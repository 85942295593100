@use 'utils/variable' as var;

.Sidebar {
  box-shadow: rgb(186 186 186 / 43%) 0px 4px 4px 0px;
  width: 70px;
  height: 100vh;
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  background: #2b303c;
  z-index: 13;

  img {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
  }

  .home-icon {
    border-radius: 5px;
    margin-top: 100px;
    padding: 0 2px;
  }

  .active {
    background: var.$main-red;
    padding: 0 2px;
    img {
    }
  }

  .work-icon {
    margin-top: 30px;
    width: 44px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .surety-icon {
    margin-top: 30px;
    width: 44px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    img {
      width: 35px;
    }
  }

  img {
    cursor: pointer;
  }
}
