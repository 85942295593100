.Header {
  height: 60px;
  box-shadow: rgb(186 186 186 / 43%) 0px 2px 4px 0px;
  background: white;
  display: flex;
  position: fixed;
  width: 100vw;
  z-index: 11;

  .logo {
    background: #262c35;
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;

    img {
      width: 27px;
      // height: 40px;
    }
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .page-title {
    display: flex;
    align-items: center;
    font-family: "Red Hat Text";
    font-weight: bold;
    font-size: 25px;
    line-height: 43px;
    color: #000000;
    margin-left: 53px;

    .go-back-icon {
      width: 20px;
      margin-right: 10px;
      margin-left: -30px;
      cursor: pointer;
      transition: 0.5s ease;

      &:hover {
        transform: translate(0, 0%) scale(1.2);
        filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(0%)
          hue-rotate(325deg) brightness(99%) contrast(100%);
      }
    }

    .page-title-wrapper {
      margin-top: -10px;

      .page-title-address {
        font-size: 11px;
        line-height: 0;
        font-weight: normal;
        color: gray;
      }
    }
  }

  .search {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      height: 35px;
      border: none;
      border-left: 1px solid #e5e5e5;
      box-sizing: border-box;
      padding-left: 30px;
      outline: none;
      color: #cdcdcd;
      font-size: 18px;

      &::placeholder {
        color: #cdcdcd;
        font-size: 18px;
      }
    }
  }

  .user {
    /* flex: 1; */
    margin-right: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 37px;
    color: #ababab;

    img {
      height: 35px;
      margin-left: 20px;
    }
  }
}

.logout-icon {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    transform: translate(0, 0%) scale(1.2);
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(0%)
      hue-rotate(325deg) brightness(99%) contrast(100%);
  }
}
