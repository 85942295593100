@use "utils/variable" as var;
.App {
  .app-content {
    overflow-y: hidden;
  }

  .main-layout {
    width: calc(100vw - 70px);
    margin-left: 55px;
  }

  .MuiPaper-root {
    background: var.$main-red;
    color: #fff;
  }
  .MuiAlert-filledSuccess {
    background: #0fcfbe;
  }
}
