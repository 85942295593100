.Loading {
  position: absolute;
  left: 70px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70px;
  top: 50%;
  z-index: 1;

  img {
    width: 70px;
  }
}
